.dashboard-container {
  position: relative;
}

.divider {
  margin: 2em;
  border: solid 1px rgba(0, 0, 0, 0.2);
}

.float-top-right {
  position: absolute;
  right: 1em;
  top: -2em;
}