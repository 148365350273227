.app-page {
  .app-page-content {
    box-shadow: 0 1px 6px 1px rgba(#000, 0.3);
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-flow: column;
    margin: 0 1em;
    background-color: rgba(white, 0.7);
    grid-gap: 3rem;
  }
}
