.status-icon-container {
    display: flex !important;
    align-items: center;
    margin-left: 10px;
}

.instance-health-details {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.health-icon {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.info-icon {
    margin-left: 5px;
    margin-right: 5px;
}
.health-text {
    vertical-align: middle;
}