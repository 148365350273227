.page-header {
  box-shadow: 0 1px 6px 1px rgba(#000, 0.3);
  background-color: rgba(black, 1);
  color: white;
  padding: 1rem;
  font-weight: 600;
  .help {
    color: #00b5ad;
    float: right;
  }

  .logout-link {
    color: white;
    float: right;

    .sign-out-icon {
      margin-left: 1rem;
      &:hover {
        cursor: pointer;
        color: #00b5ad;
      }
    }
  }
}
