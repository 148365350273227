.modal {
  .close-icon {
      float: right;
      color: rgba(0,0,0,1);
      &:hover {
          color: rgba(0,0,0,0.6);
          cursor: pointer;
      }
  }

  .action-button {
    display:flex;
    justify-content: flex-end;
    margin-top: 1rem  !important;
  }
}
